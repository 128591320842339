import React from 'react'
import {Data} from "../helpers/Data"
import MenuItem from './MenuItem'


const Menu = () => {
  return (
    <>
    <h1 className='title' >Menü</h1>
     <div className='menuListBox mb-60' >
        {
          // Map fonksiyonu geriye her zaman bir dizi döndürür . JSX dizi içindeki elemanları otomatik olarak render eder.
          Data.map((item,index)=>{
            return(
              <MenuItem item={item} key={index}></MenuItem>
            )
          })
        }
    </div>
    </>
   
  )
}

export default Menu
