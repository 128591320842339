import React from 'react'
import burgerKingContact from "../assets/burgerKingContact.png"

const Contact = () => {
  return (
    <>
      <div className='contactBox' >
          <div style={{
            backgroundImage : `url(${burgerKingContact})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: '500px',
            borderRadius: '10px',
            padding: '20px',
            margin: '20px',
            width: '50%',
          }} >



          </div>
          <div className='contantContentBox'>
            <h1>Bizimle İletişime Geçin</h1>

            <form>
              <label>Ad</label>
              <input type='text'  />
              <label>Soyad</label>
              <input type='text'  />
              <label>E-Posta Adresi</label>
              <input type='text'  />
              <label>Mesajınız</label>
              <textarea />
              <a type='submit'>Gönder</a>
            </form>


          </div>
      </div>
    </>
  )
}

export default Contact
