import React from 'react'
import BurgerMenu from "../assets/burgerMenu.jpg"

export const Data =[
        {
            ad:"Efso Burger",
            content:"Kısa ve güzel bir yiyecek. İçinde bir ham ve bir salata bulunuyor.",
            image:BurgerMenu,
            price: 250

        },
        {
            ad:"Efso Burger",
            content:"Kısa ve güzel bir yiyecek. İçinde bir ham ve bir salata bulunuyor.",
            image:BurgerMenu,
            price: 250

        }
        ,
        {
            ad:"Efso Burger",
            content:"Kısa ve güzel bir yiyecek. İçinde bir ham ve bir salata bulunuyor.",
            image:BurgerMenu,
            price: 250

        }
        ,
        {
            ad:"Efso Burger",
            content:"Kısa ve güzel bir yiyecek. İçinde bir ham ve bir salata bulunuyor.",
            image:BurgerMenu,
            price: 250

        }
        ,
        {
            ad:"Efso Burger",
            content:"Kısa ve güzel bir yiyecek. İçinde bir ham ve bir salata bulunuyor.",
            image:BurgerMenu,
            price: 250

        }
        ,
        {
            ad:"Efso Burger",
            content:"Kısa ve güzel bir yiyecek. İçinde bir ham ve bir salata bulunuyor.",
            image:BurgerMenu,
            price: 250

        }
        ,
        {
            ad:"Efso Burger",
            content:"Kısa ve güzel bir yiyecek. İçinde bir ham ve bir salata bulunuyor.",
            image:BurgerMenu,
            price: 250

        }
        ,
        {
            ad:"Efso Burger",
            content:"Kısa ve güzel bir yiyecek. İçinde bir ham ve bir salata bulunuyor.",
            image:BurgerMenu,
            price: 250

        }
        ,
        {
            ad:"Efso Burger",
            content:"Kısa ve güzel bir yiyecek. İçinde bir ham ve bir salata bulunuyor.",
            image:BurgerMenu,
            price: 250

        }
        ,
        {
            ad:"Efso Burger",
            content:"Kısa ve güzel bir yiyecek. İçinde bir ham ve bir salata bulunuyor.",
            image:BurgerMenu,
            price: 250

        }
        ,
        {
            ad:"Efso Burger",
            content:"Kısa ve güzel bir yiyecek. İçinde bir ham ve bir salata bulunuyor.",
            image:BurgerMenu,
            price: 250

        }
        ,
        {
            ad:"Efso Burger",
            content:"Kısa ve güzel bir yiyecek. İçinde bir ham ve bir salata bulunuyor.",
            image:BurgerMenu,
            price: 250

        }
        ,
    ]
