import "../styles/Footer.css"
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { NavLink } from "react-router-dom";





function Footer() {
  return (
    <footer className='footer'>
        <div className='socialLogo  mb-20'>
            <ul>
                <li><FaInstagram /></li>
                <li><FaFacebookF /></li>
                <li><FaXTwitter /></li>
                <li><FaLinkedinIn /></li>
            </ul>
             
            
            
            
        </div>

        <div className='copyright'>
            © 2004-2024 Tüm Hakları Saklıdır | <NavLink to={"/"}> burgerking.com  </NavLink>

        </div>

      
    </footer>
  )
}

export default Footer
