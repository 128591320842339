


import React from 'react'
import burgerKing from "../assets/burgerKing.png"
import {NavLink} from "react-router-dom"
import "../styles/Header.css"

function Header() {
  return (
    <header className='header' >
        <div className='headerLogo'>
            {/* Html NavLink Etiketlerini a etiketi gibi algılar   */}
            <NavLink to={"/"} > <img src={burgerKing} ></img> </NavLink>
        </div>

        <nav className='headerNav' >
            <ul>
                <NavLink to={"/"} ><li>Home</li></NavLink>
                <NavLink to={"/about"} ><li>About</li></NavLink>
                <NavLink to={"/menu"}><li>Menu</li> </NavLink>
                <NavLink to={"contact"} ><li>Contact</li> </NavLink>
            </ul>
        </nav>
    </header>
  )
}

export default Header
