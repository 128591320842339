import React from "react";
import "../styles/Menu.css";
function MenuItem({item}) {
  return (
    <div className="menuListItem">
      <img src={item.image} alt={item.ad} />
      <div className="menuListItemContent">
        <h2>{item.ad}</h2>
        <p>{item.content}</p>
        <p className="price">{item.price} TL</p>
      </div>
    </div>
  );
}

export default MenuItem;
