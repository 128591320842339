import React from 'react'
import homeBackground from "../assets/homeBackground.jpg"
import {Link} from "react-router-dom"
import "../styles/Home.css"

const Home = () => {
  return (
    <>
    {/* style={{}} yaparak jsx kodlarının içerisinde css kodlarını yazabilirsin  */}
    <div  className='homeBackground' style={{
      height:'800px',
      backgroundImage: `url(${homeBackground})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      position: 'relative'

    }} >

      <div className='orderButton' >
        <Link to={"/menu"}>SİPARİŞ VER</Link>
      </div>

    </div>

    </>

  )
}

export default Home
