import './App.css';
import {Route,Routes} from "react-router-dom";
import Home from './compoents/Home';
import Warning from './compoents/Warning';
import Contact from './compoents/Contact';
import About from './compoents/About';
import Menu from './compoents/Menu';
import Header from './compoents/Header';
import Footer from './compoents/Footer';

function App() {
  //jsx kodları retrun'un içine yazılır.
  return (
    <div className="App">
      
      <Header></Header>
      <Routes>
        <Route path='/' excat  element={<Home></Home>} ></Route>
        <Route path='/contact' excat element={<Contact></Contact>}></Route>
        <Route path='/about' excat element={<About></About>}></Route>
        <Route path='/menu' excat element={<Menu></Menu>}></Route>
        <Route path='*' excat element={<Warning></Warning>}></Route>
      </Routes>
      <Footer></Footer>
     
     
    </div>
  );
}

export default App;
