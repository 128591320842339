import React from 'react'

const Warning = () => {
  return (
    <div>

        404 Aradığın Sayfa Bulunamadı
      
    </div>
  )
}

export default Warning
